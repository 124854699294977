import Vue from 'vue'
import App from './App.vue'

//⭐引入router
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/reset.css'
import './styles/common.css'
import './assets/iconfont/iconfont.css'
import '@/assets/fonts/fonts.css';

// import FastClick from 'fastclick';
import BaiduMap from 'vue-baidu-map';

// FastClick.attach(document.body);
// console.log(store, 'store');
// console.log(router, 'router')
Vue.use(BaiduMap, {
  ak: 'GRwPoYBUrbhZLUXj3AUiGaMBoNnm0eju' // 这里填写你的百度地图ak
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
console.log(process.env.VUE_APP_BASE_URL, 'process.env.VUE_APP_BASE_URL')

// 设置哪些页面是属于白名单的
const witheList = ["/login"];

function isWitheRoute(path) {
  return witheList.includes(path);
}
// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('travlToken');
  // 判断如果是白名单就直接放行
  if (isWitheRoute(to.path)) {
    next();
    return;
  }
  // 没有登录，强制跳转到登录页面
  if (!token && to.path != "/login") {
    // toast("请先登录", "error");
    next({ path: "/login" });
    return;
  }
  //防止重复登录
  if (token && to.path == "/login") {
    // toast("请无重复登录", "error");
    return next({ path: from.path ? from.path : "/home" });
  }
  next()
});

export default router

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
